import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AccessState } from '@core/frontend-shared';
import { Select, Store } from '@ngxs/store';
import { AutoUnsubscribe, takeWhileAlive } from '@core/frontend-shared/utils';
import { Observable } from 'rxjs';

@Component({
	selector: 'mpf-login',
	styleUrls:['./login.component.scss'],
	template: `
	<mpf-layout-frontpage theme="green">
	<!-- <a class="top-menu-item" routerLink="/privacy" >Datenschutz</a>
		<a class="top-menu-item" routerLink="/imprint" >Impressum</a> -->
		<feedback-button class="top-menu-item"  buttonClass=""></feedback-button>	

		<div class="content-left">
			<h2>Die <b>einfache Lösung</b><br> für Ihr Team.</h2>
		</div>
		<div class="content-right">
			<div>
				<div class="login">
					<h2 class="margin">Login</h2>
					
					<ng-container *replaceableComponent="{id:'user-login-form',inputs:{resetPasswordEnabled:true}}"><user-login-form></user-login-form></ng-container>
				</div>
				<hr style="margin:30px 0">
				<div >
					<h2 class="margin">Registrierung</h2>
					<p>Sie haben noch keinen Zugang? Kein Problem!</p>
		
					<div class="contact-info ui-margin-medium" uiGrid>
						<div class="p-col-fixed" style="width:230px">
							<a [routerLink]="'request-demo'|resolveRouterLink" iconPos="right" uiButton icon="arrow-right" label="Jetzt gratis testen" class="ui-button-outline"></a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<ui-card size="large">
		</ui-card>
	</mpf-layout-frontpage>
	`
})
@AutoUnsubscribe()
export class AppLoginComponent {

	@Select(AccessState.loggedIn) loggedIn$:Observable<boolean>;

	constructor(
		store:Store,
		router:Router
	) {
		
		// navigate to home if user is already logged in
		this.loggedIn$.pipe(takeWhileAlive(this)).subscribe(loggedIn=>{
			if(loggedIn) router.navigate(['/'])
		})
	}

}
