import { Component } from '@angular/core';
import { ConfigService } from '@core/shared/config';

@Component({
  selector: 'mpf-download-survey',
  templateUrl: './download-survey.component.html',
  styleUrls: ['./download-survey.component.scss'],
  styles:[]
})
export class DownloadSurveyComponent {
	

}
