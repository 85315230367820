<mpf-layout-frontpage [showBottomButtons]="!(loggedIn$|async)" >
	<!-- <a class="top-menu-item" routerLink="/privacy" >Datenschutz</a>
	<a class="top-menu-item" routerLink="/imprint" >Impressum</a> -->
	<feedback-button class="top-menu-item"  buttonClass=""></feedback-button>	

	<div class="content-left">
		<div class="cover-image"></div>
		<video [autoplay]="true" [muted]="true" muted playsinline preload="metadata" class="cover-image" #video [@OutAnimation] *ngIf="videoVisible">
			<source type="video/mp4" src="https://res.cloudinary.com/meinpflegeforum/video/upload/v1624545799/meinpflegeforum/media/13176035-preview_jz63pp.mp4">
		</video>

		<button uiButton [icon]="muteState ? 'volume-mute' : 'volume-up'" [title]="muteState ? 'Ton einschalten' : 'stummschalten'" 
			class="button-umute ui-button-outline" (click)="toggleVideoMuteState()"></button>

	</div>
	<div class="content-right">

		<div *ngIf="loggedIn$|async; else guestView" e2e-landingpage-logged-in>
			<h2 *ngIf="userData$|async; let userData">Guten Tag,<br> <b
					class="color-primary">{{userData?.firstName}} {{userData?.lastName}}</b>!</h2>
			<p>Was möchten Sie tun?</p>


			<div uiGrid>
				<div class="p-sm-6 p-xxl-4">
					<div class="usermenu">
						<a class="ui-button-outline" [routerLink]="'management'|resolveRouterLink" iconPos="left" uiButton icon="university"
							label="Management" *ngIf="(hasManagerMenuItems$|async)"></a>
						<a class="ui-button-outline" [routerLink]="'profile'|resolveRouterLink" iconPos="left" uiButton icon="user"
							label="Mein Profil" ></a>
						<a class="ui-button-outline" [routerLink]="'courses'|resolveRouterLink" iconPos="left" uiButton e2e-offer-course-overview 
							icon="chalkboard-teacher" label="Kursübersicht"></a>
						<a class="ui-button-outline" [routerLink]="'logout'|resolveRouterLink" iconPos="left" uiButton icon="lock"
							label="Logout"></a>
					</div>
				</div>
				<div class="p-sm-6 p-xxl-4">
					<courses-continue-last-course></courses-continue-last-course>
				</div>
			</div>

		</div>

		<ng-template #guestView>
			<div>
				<h2>
					<b class="color-primary">Einfach</b> fortbilden mit Erfolg.<br>
				</h2>
				<p class="subline">Professionelle Schulungsfilme mit anerkannten Zertifikaten.</p>
				<h2>
					<b class="color-primary">Einfach</b> online ohne Aufwand.<br>
				</h2>
				<p class="subline">Expertenstandards und Praxisfilme für die Pflege.</p>
				<h2>
					<b class="color-primary">Einfach</b> Zeit für Menschen.<br>
				</h2>
				<p class="subline">Zeitunabhängig und individuell fortbilden.</p>
			</div>
			<br>
			<ul class="ui-iconlist medium">
				<li><ui-icon icon="check"></ui-icon> Videoschulungen von Fachkräften</li>
				<li><ui-icon icon="check"></ui-icon> Günstiger monatlicher Festpreis pro Pfleger*in</li>
				<!-- <li><ui-icon icon="check"></ui-icon> 5€ pro Pfleger*in im Monat, monatlich kündbar</li> -->
				<li><ui-icon icon="check"></ui-icon> Verwaltung Ihres Teams</li>
				<li><ui-icon icon="check"></ui-icon> Fortbildungspunkte der RbP</li>
			</ul>
			<br>
			
			<div class="content-button-menu">
				<a [routerLink]="'request-demo'|resolveRouterLink" iconPos="right" uiButton icon="arrow-right" label="Gratis testen" class="ui-button-outline large-btn"></a>

				<a href="https://wasist.meinpflegeforum.de/" target="_blank" iconPos="right" uiButton icon="video" label="Infofilm ansehen" class="ui-button-outline large-btn"></a>

				<a target="_blank" href="https://www.instagram.com/meinpflegeforum/" [icon]="['fab','instagram']" iconSize="lg" [rounded]="true"
					uiHoverLabelButton style="vertical-align: bottom;" label="zu Instagram" class="ui-button-outline"></a>

				<a target="_blank" href="https://www.facebook.com/meinPflegeforum/" [icon]="['fab','facebook']" iconSize="lg" [rounded]="true"
					uiHoverLabelButton style="vertical-align: bottom;" label="zu Facebook" class="ui-button-outline"></a>
			</div>
		</ng-template>

	</div>
</mpf-layout-frontpage>