import { DynamicFieldInstruction, applyOptionsDynamicDirectives, optionsInputDefaults, optionsInputMap } from './shared';
export class InputSelectbuttonInstruction extends DynamicFieldInstruction {
  getDefaults() {
    return {
      ...optionsInputDefaults,
      deselectable: false,
      breakpoint: 500
    };
  }
  constructor(config) {
    super();
    /** object map of input name -> source name / function */
    this.inputMap = {
      ...optionsInputMap,
      deselectable: 'deselectable',
      breakpoint: 'breakpoint'
    };
    this.attributeMap = {};
    this.setConfig(config);
    // special case: Selectbutton does not support [filter] input yet.
    // simple solution: skip assignment by deleting it from inputMap.
    delete this.inputMap['filter'];
    this.addDirectivesPlugin(applyOptionsDynamicDirectives);
  }
  load() {
    return import( /* webpackChunkName: "ui-input-selectbutton" */
    /* webpackMode: "lazy" */
    '@common/ui/frontend-shared/input-selectbutton').then(bundle => {
      return {
        component: bundle.InputSelectButton,
        module: bundle.UiInputSelectButtonModule
      };
    });
  }
}