import { Injectable } from '@angular/core';
import { AccessState } from '@core/frontend-shared';
import { Select } from '@ngxs/store';
import { FeatureMenuItem } from '@core/frontend-shared/feature';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';

const mainMenu:FeatureMenuItem[] = [
	{ label: 'Anmeldung', routerLink:'login' },
	// { label: 'Mein Profil', routerLink:'user' },
	// { label: 'Management', routerLink:'manager', visible:false },
	// { label: 'Kurse', routerLink:'courses' },
	{ label: 'Datenschutz', routerLink:'privacy' },
	{ label: 'Impressum', routerLink: 'imprint' },
];

@Injectable({providedIn:'root'})
export class MainMenuService {

	private _mainMenu$ = new BehaviorSubject(mainMenu);
	public mainMenu$;

	@Select(AccessState.loggedIn)
	isLoggedIn$:Observable<boolean>;

	@Select(AccessState.accessData('isManager'))
	isManager$:Observable<boolean>;

	constructor() {
		this.mainMenu$ = this._mainMenu$.asObservable();

		combineLatest([
			this.isLoggedIn$, 
			this.isManager$
		]).subscribe(([isLoggedIn,isManager])=>{
			const menu = this.getImmutableCurrentMenu();
			menu.forEach(item=>{
				if(item.routerLink==='login') {
					item.visible = !isLoggedIn;
				}
				if(item.routerLink==='user') {
					item.visible = isLoggedIn && !isManager;
				}
				if(item.routerLink==='manager') {
					item.visible = isManager;
				}
			})
			this._mainMenu$.next(menu)
		})
	}

	protected getImmutableCurrentMenu() {
		const copy:FeatureMenuItem[] = [];
		this._mainMenu$.value.forEach(item => {
			copy.push({...item})
		});
		return copy;
	}
	
}