/**
 * NOTE: this is an autogenerated file! do not change!
 * to change feature imports, edit /apps/<name>/shared/features.json!
 */

import {PageBaseFeature} from "@common/page/feature";
import {AbilitiesBaseFeature} from "@common/abilities/feature";
import {MediaBaseFeature} from "@common/media/feature";
import {CloudinaryBaseFeature} from "@common/cloudinary/feature";
import {FaqBaseFeature} from "@common/faq/feature";
import {MaintenanceBaseFeature} from "@common/maintenance/feature";
import {ServerlogsBaseFeature} from "@common/serverlogs/feature";
import {NotificationsBaseFeature} from "@common/notifications/feature";
import {StatisticsBaseFeature} from "@common/statistics/feature";
import {CmsBaseFeature} from "@common/cms/feature";
import {FeedbackBaseFeature} from "@common/feedback/feature";
import {MailerBaseFeature} from "@common/mailer/feature";
import {UsersBaseFeature} from "@common/users/feature";
import {TrackingBaseFeature} from "@common/tracking/feature";
import {ChangelogBaseFeature} from "@common/changelog/feature";
import {UsersPasswordResetQrcodeBaseFeature} from "@common/users-password-reset-qrcode/feature";
import {MembershipBaseFeature} from "@common/membership/feature";
import {MpfMembershipPluginPaymentBaseFeature} from "@mpf/membership-plugin-payment/feature";
import {MpfContactBaseFeature} from "@mpf/contact/feature";
import {MpfUsersBaseFeature} from "@mpf/users/feature";
import {MpfCoursesBaseFeature} from "@mpf/courses/feature";
import {MpfDocentsBaseFeature} from "@mpf/docents/feature";
import {MpfAppBaseFeature} from "@mpf/app/feature";
import {MpfOptinUnlockVideoJumpingBaseFeature} from "@mpf/optin-unlock-video-jumping/feature";
import {PageFrontendSharedFeature} from "@common/page/frontend-shared";
import {PageFrontendManagementFeature} from "@common/page/frontend-management";
import {MediaFrontendFeature} from "@common/media/frontend";
import {FaqFrontendFeature} from "@common/faq/frontend";
import {NotificationsFrontendFeature} from "@common/notifications/frontend";
import {UsersFrontendFeature} from "@common/users/frontend";
import {UsersFrontendManagementFeature} from "@common/users/frontend-management";
import {TrackingFrontendFeature} from "@common/tracking/frontend";
import {UsersPasswordResetQrcodeFrontendFeature} from "@common/users-password-reset-qrcode/frontend";
import {UsersPasswordResetQrcodeFrontendManagementFeature} from "@common/users-password-reset-qrcode/frontend-management";
import {MembershipFrontendFeature} from "@common/membership/frontend";
import {MpfContactFrontendFeature} from "@mpf/contact/frontend";
import {MpfCoursesFrontendFeature} from "@mpf/courses/frontend";
import {MpfCoursesFrontendManagementFeature} from "@mpf/courses/frontend-management";
import {MpfDocentsFrontendFeature} from "@mpf/docents/frontend";
import {MpfDocentsFrontendManagementFeature} from "@mpf/docents/frontend-management";
import {MpfAppFrontendFeature} from "@mpf/app/frontend";
import {MpfOptInUnlockVideoJumpingFrontendFeature} from "@mpf/optin-unlock-video-jumping/frontend-shared";
import {clientCacheDevToolProvider} from "@common/page/frontend-shared";
import {DevToolProviderToken} from "@core/shared/dev-tools";
import {modelMetadataDevToolDefinition} from "@common/page/frontend-shared";
import {MediaAssetProviderCloudinaryFrontend} from "@common/cloudinary/frontend-shared";
import {MEDIA_ASSET_PROVIDER_TOKEN} from "@common/media/shared";
import {AccessUserPluginFrontend} from "@common/users/frontend-shared";
import {ACCESS_SERVICE_PLUGIN_TOKEN} from "@core/shared/access";
import {AccessMembershipFrontendPlugin} from "@common/membership/frontend";
import {MpfPaymentFrontendMembershipPlugin} from "@mpf/membership-plugin-payment/frontend-shared";
import {MEMBERSHIP_PLUGINS_TOKEN} from "@common/membership/shared";
import { CoreFeatureModule } from "@core/frontend-shared";

export default [
	// include base feature info
	CoreFeatureModule.forRoot([
		PageBaseFeature,
		AbilitiesBaseFeature,
		MediaBaseFeature,
		CloudinaryBaseFeature,
		FaqBaseFeature,
		MaintenanceBaseFeature,
		ServerlogsBaseFeature,
		NotificationsBaseFeature,
		StatisticsBaseFeature,
		CmsBaseFeature,
		FeedbackBaseFeature,
		MailerBaseFeature,
		UsersBaseFeature,
		TrackingBaseFeature,
		ChangelogBaseFeature,
		UsersPasswordResetQrcodeBaseFeature,
		MembershipBaseFeature,
		MpfMembershipPluginPaymentBaseFeature,
		MpfContactBaseFeature,
		MpfUsersBaseFeature,
		MpfCoursesBaseFeature,
		MpfDocentsBaseFeature,
		MpfAppBaseFeature,
		MpfOptinUnlockVideoJumpingBaseFeature
	],[
		{token:DevToolProviderToken, value:clientCacheDevToolProvider},
		{token:DevToolProviderToken, value:modelMetadataDevToolDefinition},
		{token:MEDIA_ASSET_PROVIDER_TOKEN, value:MediaAssetProviderCloudinaryFrontend},
		{token:ACCESS_SERVICE_PLUGIN_TOKEN, value:AccessUserPluginFrontend},
		{token:ACCESS_SERVICE_PLUGIN_TOKEN, value:AccessMembershipFrontendPlugin},
		{token:MEMBERSHIP_PLUGINS_TOKEN, value:MpfPaymentFrontendMembershipPlugin}
	]),

	// load feature plugins for this platform
	PageFrontendSharedFeature,
	PageFrontendManagementFeature,
	MediaFrontendFeature,
	FaqFrontendFeature,
	NotificationsFrontendFeature,
	UsersFrontendFeature,
	UsersFrontendManagementFeature,
	TrackingFrontendFeature,
	UsersPasswordResetQrcodeFrontendFeature,
	UsersPasswordResetQrcodeFrontendManagementFeature,
	MembershipFrontendFeature,
	MpfContactFrontendFeature,
	MpfCoursesFrontendFeature,
	MpfCoursesFrontendManagementFeature,
	MpfDocentsFrontendFeature,
	MpfDocentsFrontendManagementFeature,
	MpfAppFrontendFeature,
	MpfOptInUnlockVideoJumpingFrontendFeature
] as const;

