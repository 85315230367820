<mpf-layout-frontpage theme="green">
	<a class="top-menu-item" routerLink="/privacy" >Datenschutz</a>
	<a class="top-menu-item" routerLink="/imprint" >Impressum</a>
	<feedback-button class="top-menu-item"  buttonClass=""></feedback-button>	

	<div class="content-left">
		<h2>
			Helfen Sie uns, die<br> <b>beste Lösung</b><br> für Sie zu erschaffen.
		</h2>
	</div>
	<div class="content-right">

		<div>
			<h2>Fragebogen</h2>
			<br>
			<span>Laden Sie jetzt den Fragebogen herunter <br>und lassen Sie uns wissen, was Sie brauchen!</span>
			<br>
			<br>
			<div class="contact-info">
				<div>
					<contact-info></contact-info>
				</div>
				<div>
					<a href="/assets/fragebogen.pdf" target="_blank" iconPos="right" uiButton icon="arrow-right" label="Fragebogen laden" class="ui-button-outline"></a>
				</div>
			</div>
		</div>

	</div>
</mpf-layout-frontpage>