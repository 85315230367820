import { Component } from '@angular/core';
import { InputTextareaInstruction, InputTextInstruction } from '@common/instructions/frontend-shared';
import { ModelFormService, ModelFormServiceFactory } from '@common/forms/frontend-shared';
import { AppRequestService } from '@core/frontend-shared/api';
import { ConfigService } from '@core/shared/config';
import { DemoRequestItem, WaitingListItem } from '@mpf/contact/shared';
import { FormInstructionInterface } from '@core/frontend-shared'

@Component({
	selector: 'mpf-request-demo',
	styleUrls:['./request-demo.component.scss'],
	template: `
	<mpf-layout-frontpage theme="green">
		<feedback-button class="top-menu-item"  buttonClass=""></feedback-button>	
		
		<div class="content-left">
			<h2>Die <b>einfache Lösung</b><br> für Ihr Team.</h2>
		</div>
		<div class="content-right">
			<div>
				<div class="form-wrapper">
					<h2 class="ui-margin-bottom">Jetzt loslegen.</h2>
					<div [uiGrid]="{size:'large'}" class="forms">
						<div class="p-col-12 p-md-7 p-xl-8">
							<h3 class="ui-margin-remove">Einrichtungen & Pflegedienste</h3>
							<p>Testen Sie meinPflegeforum für Ihr Unternehmen! Einfach das Formular ausfüllen und senden. 
							Unsere Mitarbeiter bereiten Ihren Unternehmensaccount vor und melden sich dann bei Ihnen.
							</p>
							<p>Nach Ablauf der Testphase entscheiden Sie, ob Sie die Plattform dauerhaft nutzen wollen.
							Wenn nicht, entstehen Ihnen keine weiteren Kosten.
							<a href="//meinpflegeforum.de/preise" target="_blank">Preisübersicht ansehen</a>
							</p>
							<mpf-contact-demo-request></mpf-contact-demo-request>
						</div>
						<div class="p-col-12 p-md-5 p-xl-4">
						<div class="col-right">
							<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
								x="0px" y="0px"	viewBox="0 0 250 241.6" xml:space="preserve">
							<rect x="33.227" y="76.591" width="185.095" height="96.865" style="fill: #fff;"></rect>
							<path style="fill:currentColor" d="M237,144.4c6.1-6.4,13-13.8,13-23.6s-6.9-17.2-13-23.6c-3.4-3.7-7-7.4-8-10.5c-1-3.3-0.4-8.7,0.3-13.9
								c1.1-8.7,2.3-18.5-3.2-26.1c-5.6-7.7-15.3-9.6-23.9-11.2c-5.1-1-10.3-2-12.9-3.9c-2.6-1.9-5.1-6.6-7.6-11.1
								c-4.2-7.8-9-16.6-18.1-19.6c-8.9-2.9-17.7,1.3-25.4,5c-4.8,2.3-9.7,4.6-13.2,4.6s-8.5-2.3-13.2-4.6C104,2.3,95.2-1.9,86.4,1
								c-9.2,3-13.9,11.8-18.1,19.6c-2.4,4.5-4.9,9.2-7.6,11.1c-2.7,2-7.9,2.9-12.9,3.9c-8.6,1.6-18.3,3.5-23.9,11.2
								c-5.5,7.7-4.3,17.5-3.2,26.1c0.7,5.2,1.3,10.6,0.3,13.9c-1,3-4.5,6.8-8,10.5C6.9,103.6,0,111,0,120.8s6.9,17.2,13,23.6
								c3.4,3.7,7,7.4,8,10.5c1,3.3,0.4,8.7-0.3,13.9c-1.1,8.7-2.3,18.5,3.2,26.1c5.6,7.7,15.3,9.6,23.9,11.2c5.1,1,10.3,2,12.9,3.9
								c2.6,1.9,5.1,6.6,7.6,11.1c4.2,7.8,9,16.6,18.1,19.6c8.9,2.9,17.7-1.3,25.4-5c4.8-2.3,9.7-4.6,13.2-4.6s8.5,2.3,13.2,4.6
								c5.9,2.8,12.6,6,19.3,6c2.1,0,4.1-0.3,6.2-1c9.2-3,13.9-11.8,18.1-19.6c2.4-4.5,4.9-9.2,7.6-11.1c2.7-2,7.9-2.9,12.9-3.9
								c8.6-1.6,18.3-3.5,23.9-11.2c5.5-7.7,4.3-17.5,3.2-26.1c-0.7-5.2-1.3-10.6-0.3-13.9C230,151.9,233.6,148.1,237,144.4z M88,151.1
								l-30.6-29.7l4.1,33L48.1,156L41,98.3L51.4,97l31.1,30.6l-4.2-33.9L91.6,92l7.1,57.7L88,151.1z M109.7,148.4l-7.1-57.7l39.8-4.9
								l1.4,11.7l-26.5,3.3l1.4,11.2l22.8-2.8l1.3,10.8l-22.8,2.8l1.5,12.3l27.2-3.3l1.4,11.7L109.7,148.4z M205.2,119.4
								c-0.6,3.6-1.7,6.8-3.5,9.7c-1.8,2.9-4.2,5.2-7.2,7.1c-3,1.9-6.8,3.1-11.2,3.6c-4.6,0.6-8.6,0.3-12.1-0.9c-3.4-1.2-6.3-2.9-8.7-5.3
								c-2.4-2.4-4.2-5.2-5.6-8.6c-1.4-3.4-2.3-6.9-2.8-10.7l-3.6-29.5l13.3-1.6l3.6,29.5c0.3,2.2,0.7,4.3,1.4,6.3s1.6,3.7,2.8,5.1
								c1.2,1.5,2.6,2.5,4.2,3.3c1.7,0.7,3.6,1,5.9,0.7c2.3-0.3,4.1-1,5.6-2.1s2.6-2.6,3.4-4.2c0.8-1.7,1.3-3.6,1.4-5.7
								c0.2-2.1,0.1-4.2-0.2-6.3l-3.6-29.5l13.3-1.6l3.6,29.5C205.8,112,205.8,115.8,205.2,119.4z"/>
							</svg>
							<div class="svg-overlay">
								<h3 class="ui-margin-remove">Jetzt auch für Einzelpersonen!</h3>
								<p>Sie möchten sich privat oder unabhängig von Ihrem Unternehmen fortbilden? Kein Problem!
									Per Mitgliedschaft erhalten Sie für ein Jahr Zugriff auf alle Inhalte.
								</p>
								<button uiButton label="Mitgliedschaft starten" [routerLink]="'membership-registration'|resolveRouterLink"></button>
							</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<ui-card size="large">
		</ui-card>
	</mpf-layout-frontpage>
	`
})
export class RequestDemoComponent {
	demoRequestService:ModelFormService;
	demoRequestFields:FormInstructionInterface[] = [
		new InputTextInstruction({ formControlName:'name',placeholder:'Vor- und Nachname' }),
		new InputTextInstruction({ formControlName:'company',placeholder:'Unternehmen / Einrichtung' }),
		new InputTextInstruction({ formControlName:'email',placeholder:'Ihre E-Mail-Adresse' }),
		new InputTextInstruction({ formControlName:'phone',placeholder:'Telefonnr.' }),
		new InputTextareaInstruction({ formControlName:'message',height:2,autoResize:true,placeholder:'Nachricht (optional)' }),
	];

	waitingListService:ModelFormService;
	waitingListFields:FormInstructionInterface[] = [
		new InputTextInstruction({ formControlName:'name',placeholder:'Vor- und Nachname' }),
		new InputTextInstruction({ formControlName:'email',placeholder:'Ihre E-Mail-Adresse' }),
	];

	constructor(
		protected api:AppRequestService,
		formServiceFactory:ModelFormServiceFactory,
		config:ConfigService
	) {

		this.demoRequestService = formServiceFactory.createModelForm(DemoRequestItem);
		this.demoRequestService.onAfterSave.subscribe(() => {})
		this.demoRequestService.initialize();
		
		this.waitingListService = formServiceFactory.createModelForm(WaitingListItem);
		this.waitingListService.setInitialValue({type:'einzelperson'})
		this.waitingListService.onAfterSave.subscribe(() => {})
		this.waitingListService.initialize();
	}
}
